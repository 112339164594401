<template>
  <div class="record-main">
    <div class="record-list" v-if="lists.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load= "onLoad"
        >
          <van-cell v-for="(item, index) in lists" :key="index">
            <div class="list-item">
              <div class="head">
                <p>编号：{{ item.num }}</p>
                <span :class="item.state ? 'status' : 'status fail'">{{item.state ? '充值成功' : '充值失败'}}</span>
              </div>
              <div class="inner">
                <div class="cell-group">
                  <div class="cell"><p><span class="name">项目：</span>{{ item.name }}</p></div>
                  <p><span>金额：</span>{{ item.price }}</p>
                </div>
                <div class="cell-group">
                  <div class="cell"><p><span class="name">号码：</span>{{ item.cellphone }}</p></div>
                  <p><span>支付方式</span>{{ item.style }}</p>
                </div>
                <div class="cell-group">
                  <p><span class="name">支付日期：</span>{{ item.date }}</p>
                </div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-else class="record-no-data">
       <svg-icon icon-class="no_data" />
        <p class="text">没有任何充值记录！</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { List, PullRefresh, Cell } from 'vant'
import { mapState } from 'vuex'
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Cell)
export default {
  name: 'user-record',
  data () {
    return {
      loading: false,
      finished: false,
      refreshing: false
    }
  },
  computed: {
    ...mapState({
      lists: state => state.usercenter.lists
    })
  },
  created () {

  },
  methods: {
    onLoad () {
      setTimeout(() => {
        if (this.refreshing) {
          this.lists = []
          this.refreshing = false
        }
        this.refreshing = false
        if (this.lists.length >= 1) {
          this.finished = true
        }
      }, 1000)
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
  .record-main{
    padding-top: 1.625rem;
  }
  .record-list{
    padding: 0 0.75rem 0 0.9375rem;

    .list-item{
      border:1px solid #B1DDFF;
    }
    .head{
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.625rem;
      font-size: 0.75rem;
      background-color: #DCF0FF;
      .status{
        font-weight: bold;
        color:#09CD34;
      }
      .fail{
        color: #ff6b00;
      }
    }
    .van-cell{
      padding: 0;
      &::after{
        display: none;
      }
    }
    .inner{
      padding: 0.625rem;
      font-size: 0.75rem;
      line-height: 1.625rem;
      .cell-group{
        display: flex;
      }
      .name{
        color:#999;
      }
      .cell{
        width: 54.62%;
        margin-right: 0.625rem;
        p{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  .record-no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    .svg-icon{
      width: 11.25rem;
      height: 9.1125rem;
    }
    .text{
        margin-top: 1.625rem;
        color: #666;
      }
  }
</style>
